export enum StreakInfoType {
  Known = 'StreakKnown',
  Unknown = 'StreakUnknown',
}

export type StreakInfo = {
  freezes_available: number;
  streak: {
    created_at?: string;
    days: number;
    last_incremented_at?: string;
  };
  streak_deadline: string;
  streak_goal_met: boolean;
  streak_week_view: Array<{
    date: string;
    status: 'met' | 'unmet' | 'frozen';
    weekday: 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
  }>;
};

export type StreakInfoKnownStateV2 = {
  current: StreakInfo;
  dailyXp: number;
  next: StreakInfo;
  streakGoal: number;
  type: StreakInfoType.Known;
  version: 2;
};

export type StreakInfoUnknownState = {
  type: StreakInfoType.Unknown;
};

export type StreakInfoKnownState = {
  createdAt?: string;
  dailyXp: number;
  deadline: number;
  incrementedAt?: string;
  lengthInDays: number;
  type: StreakInfoType.Known;
  version: 1;
};

export type StreakInfoState =
  | StreakInfoUnknownState
  | StreakInfoKnownState
  | StreakInfoKnownStateV2;
