import { Reducer } from '../../../interfaces/State';
import {
  HIDE_DAILY_STREAK_SCREEN,
  SHOW_DAILY_STREAK_SCREEN,
} from '../../actions';

export type StreakScreenState = {
  show: boolean;
};

const initialState: StreakScreenState = {
  show: false,
};

const streakScreenReducer: Reducer<StreakScreenState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SHOW_DAILY_STREAK_SCREEN:
      return {
        ...state,
        show: true,
      };
    case HIDE_DAILY_STREAK_SCREEN:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default streakScreenReducer;
