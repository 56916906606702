import { RunCodeParams } from '@datacamp/learn-jsonrpc-mux-methods';
import {
  ISessionStatus,
  IStartOptions,
  RemoteSessionOutput,
} from '@datacamp/multiplexer-client-jsonrpc';
import cookies from 'browser-cookies';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import { getServiceFromState } from '../../helpers/exercises';
import { PartialSubmitCodeSettings } from '../../helpers/exercises/base';
import {
  BackendError,
  MultiplexerJsonRpcSessionManager,
  User,
} from '../../helpers/jsonRpc/multiplexerJsonRpcSessionManager';
import { sendCommand } from '../../helpers/jsonRpc/sendCommand';
import { State } from '../../interfaces/State';
import * as actions from '../actions';
import { Action, IBootSucceedAction } from '../actions';
import * as selectors from '../selectors';
import { BACKEND_STATUS } from '../selectors';

import { getImageAndRuntimeConfig } from './getImageAndRuntimeConfig';

export const JSON_RPC_LANGUAGES = ['java', 'rust'];

export type SessionOutput = BackendError | RemoteSessionOutput;

export const epicRegisterJsonRpcSession = (
  action$: ActionsObservable<IBootSucceedAction>,
  store: Store<State, Action>,
): Rx.Observable<Action> => {
  const muxStateSubject$ = new Rx.BehaviorSubject<ISessionStatus>({
    status: 'busy',
  });
  return action$.ofType(actions.BOOT_SUCCEEDED).switchMap((action) => {
    const state = store.getState();
    if (selectors.selectIsUserNotLoggedIn(state)) {
      return [
        actions.epicUpdateBackendStatus({
          status: BACKEND_STATUS.READY.code,
        }),
      ];
    }

    const language = selectors.selectLanguage(state);
    if (!JSON_RPC_LANGUAGES.includes(language)) {
      return [];
    }

    const user = pick(action.entities.user?.settings, [
      'authentication_token',
      'email',
    ]);
    const userResult = User.safeParse(user);
    if (!userResult.success) {
      return [];
    }

    const course = selectors.selectCourse(state).toJS();
    const exercise = selectors.selectExercise(state).toJS();
    const images = selectors.selectImages(state);
    const { image, runtimeConfig } = getImageAndRuntimeConfig({
      course,
      exercise,
      images,
    });

    const query = selectors.selectQuery(state).toJS();
    const baseStartOptions: IStartOptions = {
      language: selectors.selectLanguage(state),
      course_id: selectors.selectCourseId(state),
      runtime_config: runtimeConfig,
      image,
      shared_image: selectors.selectSharedImage(state),
      excluded_session_id: null,
      ...query,
    };
    const muxSessionManager = new MultiplexerJsonRpcSessionManager({
      user: userResult.data,
      startOptions: baseStartOptions,
    });
    muxSessionManager.subscribeToStatus((status) =>
      muxStateSubject$.next(status),
    );

    return Rx.Observable.merge(
      Rx.Observable.of(actions.setMuxSessionManager(muxSessionManager)),
      Rx.Observable.of(actions.epicMuxRegistered()),
      muxStateSubject$.distinctUntilChanged(isEqual).map((status) =>
        actions.epicUpdateBackendStatus({
          status: status.status,
          message: status.message,
        }),
      ),
    );
  });
};

export const epicStartJsonRpcSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
): Rx.Observable<Action> => {
  return action$
    .ofType(actions.EPIC_START_SESSION)
    .combineLatest(
      action$.ofType(actions.EPIC_MUX_REGISTERED),
      (startAction) => startAction,
    )
    .withLatestFrom(
      action$.ofType(actions.EPIC_START_SESSION),
      (_ignored, startSessionAction) => startSessionAction,
    )
    .do((startSessionAction) => {
      if (startSessionAction.force_new) {
        cookies.erase('mux-session-id');
        cookies.erase('AWSELB');
      }
    })
    .concatMap((startSessionAction) => {
      const state = store.getState();
      const muxSessionManager = selectors.selectMuxSessionManager(state);
      if (!muxSessionManager) {
        return Rx.Observable.empty();
      }
      const course = selectors.selectCourse(state).toJS();
      const exercise = selectors.selectExercise(state).toJS();
      const sessionId = selectors.sessionId(state);
      const images = selectors.selectImages(state);
      const { image, runtimeConfig } = getImageAndRuntimeConfig({
        course,
        exercise,
        images,
      });
      const query = selectors.selectQuery(state).toJS();
      const startOptions: IStartOptions = {
        language: selectors.selectLanguage(state),
        course_id: selectors.selectCourseId(state),
        runtime_config: runtimeConfig,
        image,
        shared_image: selectors.selectSharedImage(state),
        force_new: startSessionAction.force_new,
        specific_session_id_or_new: sessionId,
        ...query,
      };
      return Rx.Observable.from(muxSessionManager.start(startOptions)).map(
        () => {
          if (muxSessionManager.sessionId == null) {
            return actions.noSession();
          }
          return actions.setSessionId({
            sessionId: muxSessionManager.sessionId,
          });
        },
      );
    });
};

export const epicSubmitJsonRpcCode = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
): Rx.Observable<unknown> => {
  return action$
    .ofType(actions.EPIC_SUBMIT_CODE)
    .map((action) => {
      const state = store.getState();
      const commandConfig: PartialSubmitCodeSettings = getServiceFromState(
        state,
      ).prepareSubmit(state, action);
      return {
        type: action.type,
        timestamp: action.timestamp,
        ...commandConfig,
      };
    })
    .throttleTime(500)
    .concatMap((commandConfig) => {
      const state = store.getState();
      const muxSessionManager = selectors.selectMuxSessionManager(state);
      if (
        muxSessionManager === null ||
        muxSessionManager.client.remoteSession === null
      ) {
        return Rx.Observable.empty();
      }

      const runParams = {
        language: commandConfig.language,
        code: commandConfig.code,
      };
      const runParamsResult = RunCodeParams.safeParse(runParams);
      if (!runParamsResult.success) {
        return Rx.Observable.empty();
      }
      const onCodeSubmitted = actions.onCodeSubmitted({
        language: runParamsResult.data.language,
        timestamp: commandConfig.timestamp,
      });
      return Rx.Observable.from(
        sendCommand({
          muxSessionManager,
          commandConfig: {
            code: commandConfig.code,
            language: commandConfig.language,
            sct: commandConfig.sct,
            command: commandConfig.command,
          },
        }),
      )
        .map((sessionOutput) => {
          const filteredOutput = sessionOutput.filter(
            (output) => output.type !== 'sct',
          );
          return [
            actions.setConsoleOutput(filteredOutput),
            actions.resultExercise({
              results: sessionOutput,
            }),
            onCodeSubmitted,
          ];
        })
        .catch((error: unknown) => {
          const backendError = error instanceof Error ? error.message : error;
          return [
            actions.epicBackendError({
              type: 'backend-error',
              payload: backendError,
            }),
            onCodeSubmitted,
          ];
        });
    });
};

export const epicStopJsonRpcSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
): Rx.Observable<Action> => {
  const state = store.getState();
  const muxSessionManager = selectors.selectMuxSessionManager(state);
  const filterFunc = (): boolean =>
    muxSessionManager !== null &&
    muxSessionManager.client.remoteSession !== null;
  return Rx.Observable.merge(
    action$
      .ofType(actions.NO_SESSION)
      .filter(filterFunc)
      .do(() => muxSessionManager?.client.closeConnection())
      .concatMapTo(Rx.Observable.empty()),
    action$
      .ofType(actions.STOP_BACKEND_SESSION)
      .filter(filterFunc)
      .do(() => muxSessionManager?.stop())
      .concatMapTo(Rx.Observable.empty()),
  ).concatMapTo(Rx.Observable.empty());
};
