import { Reducer } from '../../../../interfaces/State';
import {
  BOOT_SUCCEEDED,
  EXERCISE_COMPLETED,
  ExerciseCompletedAction,
  IBootSucceedAction,
} from '../../../actions';
import {
  StreakInfo,
  StreakInfoKnownStateV2,
  StreakInfoState,
  StreakInfoType,
} from '../types';

const initialState: StreakInfoState = { type: StreakInfoType.Unknown };

const handleBootSucceeded = (
  state: StreakInfoState,
  action: IBootSucceedAction,
): StreakInfoState => {
  const { streakInfo } = action.entities;

  if (streakInfo == null) {
    return state;
  }

  const {
    daily_xp: dailyXp,
    streak_goal: streakGoal,
    streak_state_after_goal_met: next,
    ...current
  } = streakInfo;

  return {
    version: 2,
    type: StreakInfoType.Known,
    streakGoal,
    dailyXp,
    current,
    next,
  };
};

function findNextDeadline(
  action: ExerciseCompletedAction,
  baseDeadline: string,
): Date {
  const nextDeadline = new Date(baseDeadline);

  while (nextDeadline.getTime() < action.currentTime) {
    nextDeadline.setDate(nextDeadline.getDate() + 1);
  }

  return nextDeadline;
}

function handleExerciseCompletedBeforeNextDeadline(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { streakGoal } = state;

  const hasReachedDailyGoal =
    state.current.streak_goal_met && action.xpGained >= streakGoal;

  if (!hasReachedDailyGoal) {
    return handleExerciseCompletedAfterDeadline(state, action);
  }

  // If goal was reached in the interval we move the current state forward
  const updatedState = handleDailyGoalReached(state, {
    type: EXERCISE_COMPLETED,
    xpGained: 0,
    currentTime: action.currentTime,
  });

  // Then update the daily XP
  updatedState.dailyXp = action.xpGained;

  return updatedState;
}

function handleExerciseCompletedAfterDeadline(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { current, next } = state;

  const newDeadline = findNextDeadline(action, current.streak_deadline);

  const newNextDeadline = new Date(newDeadline);
  newNextDeadline.setDate(newNextDeadline.getDate() + 1);

  // TODO: properly handle freezes
  const updatedState: StreakInfoKnownStateV2 = {
    ...state,
    dailyXp: 0,
    current: {
      ...current,
      streak_goal_met: false,
      streak_deadline: newDeadline.toISOString(),
      streak_week_view: current.streak_week_view, // TODO: fixup
      streak: { days: 0 },
    },
    next: {
      freezes_available: 0,
      streak_goal_met: true,
      streak_deadline: newNextDeadline.toISOString(),
      streak_week_view: next.streak_week_view, // TODO: fixup
      streak: { days: 1 },
    },
  };

  return handleExerciseCompletedBeforeDeadline(updatedState, action);
}

function handleXPIncrease(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { dailyXp } = state;

  const newDailyXp = dailyXp + action.xpGained;

  return {
    ...state,
    dailyXp: newDailyXp,
  };
}

function handleDailyGoalReached(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { current, dailyXp, next } = state;

  const newDailyXp = dailyXp + action.xpGained;

  const updatedCurrent: StreakInfo = {
    ...current,
    ...next,
    streak: {
      ...current.streak,
      ...next.streak,
      last_incremented_at: new Date(
        action.currentTime || Date.now(),
      ).toISOString(),
    },
  };

  const nextDeadline = new Date(next.streak_deadline);
  nextDeadline.setDate(nextDeadline.getDate() + 1);

  const updatedNext: StreakInfo = {
    ...next,
    streak_deadline: nextDeadline.toISOString(),
    streak: {
      ...next.streak,
      days: next.streak.days + 1,
    },
  };

  return {
    ...state,
    dailyXp: newDailyXp,
    next: updatedNext,
    current: updatedCurrent,
  };
}

function handleExerciseCompletedBeforeDeadline(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
) {
  const { dailyXp, streakGoal } = state;

  const newDailyXp = dailyXp + action.xpGained;
  const hasMetDailyGoal = dailyXp < streakGoal && newDailyXp >= streakGoal;

  return hasMetDailyGoal
    ? handleDailyGoalReached(state, action)
    : handleXPIncrease(state, action);
}

const handeExerciseCompleted = (
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 => {
  const didMissCurrentDeadline =
    action.currentTime > Date.parse(state.current.streak_deadline);

  if (didMissCurrentDeadline) {
    const didMissNextDeadline =
      action.currentTime > Date.parse(state.next.streak_deadline);

    return didMissNextDeadline
      ? handleExerciseCompletedAfterDeadline(state, action)
      : handleExerciseCompletedBeforeNextDeadline(state, action);
  }

  return handleExerciseCompletedBeforeDeadline(state, action);
};

const streakInfoReducer: Reducer<StreakInfoState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BOOT_SUCCEEDED: {
      return handleBootSucceeded(state, action);
    }
    case EXERCISE_COMPLETED:
      if (state.type === StreakInfoType.Unknown) {
        return state;
      }
      return handeExerciseCompleted(state as StreakInfoKnownStateV2, action);
    default:
      return state;
  }
};

export default streakInfoReducer;
