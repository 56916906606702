import backendSession from './backendSession';
import backendSessionJsonRpc from './backendSessionJsonRpc';
import boot from './boot';
import chapter from './chapter';
import codeExplanation from './codeExplanation';
import contentAuthorization from './contentAuthorization';
import course from './course';
import datawarehouseSession from './datawarehouseSession';
import exercises from './exercises';
import { images } from './images';
import learningMode from './learningMode';
import { learningRecap } from './learningRecap';
import location from './location';
import mobilePopup from './mobilePopup';
import notes from './notes';
import onboardingMilestones from './onboardingMilestones';
import output from './output';
import preFetchedData from './preFetchedData';
import settings from './settings';
import streakInfo from './streaks/streakInfo';
import streakScreen from './streaks/streakScreen';
import systemStatus from './systemStatus';
import user from './user';

export default {
  backendSessionJsonRpc,
  backendSession,
  datawarehouseSession,
  chapter,
  course,
  exercises,
  location,
  settings,
  systemStatus,
  user,
  boot,
  preFetchedData,
  images,
  mobilePopup,
  onboardingMilestones,
  contentAuthorization,
  streakInfo,
  streakScreen,
  learningMode,
  notes,
  output,
  codeExplanation,
  learningRecap,
};
